<template>
  <div class="task_warp">
    <!-- 任务列表头部区域 -->
    <div class="task_top">
      <!-- <div class="title">导入导出任务</div> -->
      <el-tabs v-model="activeName" @tab-click="handleClickTitle" class="titleTab">
        <el-tab-pane label="导入任务" name="import"></el-tab-pane>
        <el-tab-pane label="导出任务" name="export"></el-tab-pane>
      </el-tabs>

      <div class="task-top-opt">
        <div class="task_tabs">
          <div
            class="task_tabs_item"
            :class="{ tab_active: activeTab === 0 }"
            @click="handleTabClick(0)"
          >
            近1个月
          </div>
          <div
            class="task_tabs_item"
            :class="{ tab_active: activeTab === 1 }"
            @click="handleTabClick(1)"
          >
            近3个月
          </div>
        </div>

        <el-button icon="el-icon-refresh-right" class="resetBtn" @click="GetTaskList"></el-button>
      </div>
    </div>

    <!-- 表格筛选区域 -->
    <div v-if="filterList.length > 0" class="datalist-title-filter">
      <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
        {{ getFlitersTitle(item.name) }}: {{ item.label }}
        <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
      </div>
      <el-button
        v-if="filterList.length"
        class="datalist-title-button"
        type="text"
        @click="deleteAllFilter"
      >
        重置
      </el-button>
    </div>

    <!-- 任务列表主体区域 -->
    <div class="task_content" :class="{ 'height-sty': totalNum === 0 }" v-show="!requestData">
      <eyao-table
        ref="table"
        :table-data="tableData"
        :total-num="totalNum"
        @filter-change="filterChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        @sort-change="hanldeSortChange"
      >
        <el-table-column
          v-for="column in configList"
          :key="`${$route.name}-${column.property}`"
          :label="column.title"
          :prop="column.property"
          :width="column.width"
          :sortable="column.sort && 'custom'"
        >
          <template slot="header" slot-scope="scope">
            <!-- 表头 -->
            <eyao-table-search-header
              v-if="column.showIcon"
              :form-type="column.showIcon"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :options="valueOpts[column.property]"
              @search="searchOpts"
            ></eyao-table-search-header>
            <!-- 无icon -->
            <template v-else>{{ column.title }}</template>
          </template>

          <template slot-scope="scope">
            <!-- 涉及时间字段，format值 -->
            <template v-if="column.format">
              {{ scope.row[column.property] | getFormatTime }}
            </template>

            <!-- 状态列 添加icon -->
            <div v-else-if="column.property === 'status'" class="status-column">
              <i
                v-if="scope.row.status === '排队中'"
                class="status_flag"
                :style="{ color: '#FA9600', background: '#FA9600' }"
              />
              <i
                v-else-if="scope.row.status === '执行中'"
                class="status_flag"
                :style="{ color: '#1664FF', background: '#1664FF' }"
              />
              <i
                v-else-if="scope.row.status === '已完成'"
                class="status_flag"
                :style="{ color: '#04AF3E', background: '#04AF3E' }"
              />
              <i v-else class="status_flag" :style="{ color: '#E63F3F', background: '#E63F3F' }" />

              {{ scope.row[column.property] }}
            </div>

            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>

        <!-- 操作列  -->
        <el-table-column width="280" label="操作" fixed="right">
          <div slot-scope="scope" class="opt-column">
            <!-- 在任务类型为"导入"并且在任务状态为"已完成"时显示 -->
            <div
              v-if="activeName === 'import' && scope.row.status === '已完成'"
              :style="{ color: '#0069FA', marginRight: '24px' }"
              @click="handleViewLog(scope.$index, scope.row)"
            >
              查看日志
            </div>

            <!-- 在任务状态为"已完成"显示 -->
            <div
              v-if="scope.row.status === '已完成'"
              :style="{ color: '#0069FA', marginRight: '24px' }"
              @click="handleDownload(scope.$index, scope.row)"
            >
              下载结果
            </div>

            <!-- 在任务类型为"导入"并且任务状态为"已完成"时 -->
            <div
              v-if="activeName === 'import' && scope.row.status === '已完成'"
              :style="{ color: '#0069FA' }"
              @click="hanldeViewImportData(scope.$index, scope.row)"
            >
              查看导入数据
            </div>

            <!-- 在任务状态为“任务失败”时显示 -->
            <div v-if="scope.row.status === '任务失败'" :style="{ color: '#1C2028' }">
              失败原因
              <el-tooltip effect="dark" :content="scope.row.fail_reason" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </div>
          </div>
        </el-table-column>
        <template v-slot:empty>
          <div class="search-no-data" v-if="totalNum === 0 && !requestData">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>

      <!-- <div v-if="totalNum === 0 && !requestData" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->
    </div>

    <template v-if="requestData">
      <div class="listLoading">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </template>

    <!-- 导入日志drawer -->
    <el-drawer
      title="导入日志"
      class="import-log-drawer-warp"
      :visible.sync="importLogDrawer"
      direction="rtl"
      :size="480"
      :before-close="handleImportLogClose"
    >
      <div class="import-log-drawer-content">
        <div class="import-log-time">
          导入用时:
          <span>{{ importLogDetail.logTime }}</span>
        </div>
        <div class="import-log-num">
          导入数据总数:
          <span>{{ importLogDetail.logNum }}</span>
        </div>
        <div class="import-log-success">
          导入成功:
          <span>{{ importLogDetail.logSuccess }}</span>
        </div>
        <div class="import-log-fail">
          导入失败:
          <span>{{ importLogDetail.logFail }}</span>
        </div>
        <div class="import-log-create">
          新建数据:
          <span>{{ importLogDetail.logCreate }}</span>
        </div>
        <div class="import-log-update">
          更新数据:
          <span>{{ importLogDetail.logUpdate }}</span>
        </div>
        <div class="import-log-delete">
          删除数据:
          <span>{{ importLogDetail.logDelete }}</span>
        </div>
      </div>
    </el-drawer>

    <!-- 查看数据导入drawer -->
    <el-drawer
      title="查看导入数据"
      class="view-import-data-drawer-warp"
      :visible.sync="viewImportDrawer"
      direction="rtl"
      :size="960"
      :before-close="handleviewImportClose"
    >
      <div v-if="isOrg">
        <eyao-table
          class="view-import-data-table"
          :table-data="viewExportList"
          :total-num="viewExportListNum"
          @size-change="viewExportSizeChange"
          @current-change="viewExportCurrentChange"
        >
          <el-table-column
            v-for="column in viewExportConfig"
            :key="`${$route.name}-viewExport-${column.property}`"
            :label="column.title"
            :prop="column.property"
            :width="column.width"
          >
            <template slot-scope="scope">
              <!-- name字段hover展示hco_code与hcp_code -->
              <template v-if="column.property === 'name'">
                <el-popover
                  placement="top-start"
                  width="160"
                  trigger="hover"
                  :content="
                    viewExportInfo.type === '机构' ? scope.row.hco_code : scope.row.hcp_code
                  "
                >
                  <span
                    slot="reference"
                    :style="{ color: '#0069FA', cursor: 'pointer' }"
                    @click="gotoDetail(scope.row.id, scope.row.name, scope.row.hco_type_name2)"
                  >
                    {{ scope.row[column.property] }}
                  </span>
                </el-popover>
              </template>

              <!-- 所属机构名称跳转详情 -->
              <template v-else-if="column.property === 'hco_name'">
                <div
                  @click="gotoHcoName(scope.row.hco_name, scope.row.hco_id)"
                  :style="{ color: '#0069FA', cursor: 'pointer' }"
                >
                  {{ scope.row[column.property] }}
                </div>
              </template>

              <!-- 涉及时间字段，format值 -->
              <template v-else-if="column.format">
                <template v-if="column.property === 'establishment_date'">
                  {{ scope.row[column.property] | getYMD }}
                </template>
                <template v-else>{{ scope.row[column.property] | getFormatTime }}</template>
              </template>

              <template v-else>{{ scope.row[column.property] }}</template>
            </template>
          </el-table-column>
          <template v-slot:empty>
            <div class="search-no-data">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div>
          </template>
        </eyao-table>

        <!-- <div v-if="viewExportListNum === 0" class="search-no-data">
          <img
            src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
            alt="未查询到相关数据"
          />
          未查询到相关数据
        </div> -->
      </div>
      <div v-else class="xiaqu">
        <el-table
          :header-cell-style="headercellstyle"
          :cell-style="{ background: '#fff' }"
          :data="JurisdictionTableData"
          v-loading="loading"
        >
          <el-table-column
            min-width="200"
            v-for="item in tableDataHead"
            :key="item"
            align="center"
            :prop="item"
            :label="item"
          ></el-table-column>
        </el-table>
        <div class="no-data" v-if="!tableData.length && !loading">
          <img src="./img/no-data.svg" alt="" />
          <p>当前暂无生效辖区数据，请导入数据并在管理配置中设置生效</p>
        </div>
        <div class="pagination" v-if="tableData.length">
          <el-pagination
            @size-change="handleJurisdictionSizeChange"
            @current-change="handleJurisdictionCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            :current-page="JurisdictionIndex"
            layout="total, prev, pager, next, sizes, jumper"
            :total="num"
          ></el-pagination>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import store from '@/store';
import config from './config';
import configLabel from './configLabel';
import hospitalConfig from '../data-list/config/hospital';
import dealerConfig from '../data-list/config/dealer';
import shopConfig from '../data-list/config/shop';
import doctorConfig from '../data-list/config/doctor';
import hospitalLabelConfig from '../data-list/labelConfig/hospital';
import doctorLabelConfig from '../data-list/labelConfig/doctor';
import eyaoTableSearchHeader from '@/components/table-search-header';
import eyaoTable from '@/components/table';
import * as apis from '@/api/data';
import { getLastMonth, getLastThreeMonths, dateFormat } from '@/utils/util';
import papaparse from 'papaparse';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import { hcoCustom } from '../data-list/customField';
import { REQUEST_TITLE_TYPE } from '@/utils/constant';
import { GetObjURL } from '@/api/upload';
import { GetJurisdictionData } from '@/api/jurisdiction';

export default {
  name: 'ImportExport',
  components: {
    eyaoTableSearchHeader,
    eyaoTable,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm');
      }
      return '';
    },
    getYMD(value) {
      if (value && !value.startsWith('0')) {
        const date = new Date(value);
        let year = date.getFullYear();
        year = year < 10 ? `0${year}` : year;
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
      }
      return '';
    },
  },
  data() {
    return {
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
        height: '38px',
      },
      JurisdictionTableData: [],
      tableDataHead: [],
      num: 0,
      loading: true,
      isOrg: false,
      JurisdictionIndex: 1,
      JurisdictionPage: 10,

      activeName: 'import',
      activeTab: 0,
      // tab时间
      tabMonth: [],
      // 表格数据
      tableData: [],
      totalNum: 0,
      // 配置文件
      configList: [],
      configLabel: [],
      // 筛选条件
      valueOpts: {
        task_type: [
          { label: '导出', value: 1 },
          { label: '导入', value: 2 },
        ],
        ope_database: [
          { label: '数据中心', value: 1 },
          { label: '数据中转区', value: 2 },
        ],
        data_type: [
          { label: '机构', value: 1 },
          { label: '医疗专业人士', value: 2 },
          { label: '辖区', value: 3 },
        ],
        status: [
          { value: 1, label: '排队中' },
          { value: 2, label: '执行中' },
          { value: 3, label: '已完成' },
          { value: 4, label: '任务失败' },
        ],
      },
      filterObject: {},
      // 表头筛选条件显示的列表
      filterList: [],
      // 数据请求
      requestData: true,
      currentDataPage: 1,
      pageDataSize: 20,
      propertyName: [
        'task_type',
        'ope_database',
        'data_type',
        'status',
        'type_name',
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'standard_dept_name',
        'professional_title',
        'org_type',
        'administrative_title',
        'academic_title',
      ],
      // 时间排序
      orderTime: {},
      // 导入日志drawer
      importLogDrawer: false,
      importLogDetail: {
        logTime: '',
        logNum: '',
        logSuccess: '',
        logFail: '',
        logCreate: '',
        logUpdate: '',
        logDelete: '',
      },
      // 查看导入数据
      viewImportDrawer: false,
      viewExportList: [],
      viewExportConfig: [],
      viewExportLabel: [],
      viewExportListNum: 0,
      viewExportCurPage: 1,
      viewExportPageSize: 20,
      viewExportInfo: {
        type: '机构',
        id: '',
      },
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 用户名
    userName() {
      const usr = JSON.parse(localStorage.getItem('userName'));
      if (!this.$store.state.user.userName && usr) {
        this.$store.commit('user/updateUserName', usr);
      } else {
        return this.$store.state.user.userName;
      }
      return usr;
    },
    optWidth() {
      return this.activeName === 'import' ? 280 : 160;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'import-export') {
        this.init();
      }
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.deleteAllFilter();
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.deleteAllFilter();
    next();
  },

  mounted() {
    store.commit('user/setBreadcrumb', [
      { name: '导入导出任务', path: '/dashboard/import-export' },
    ]);

    this.init();
    if (this.$route.query.type) {
      this.hanldeViewImportData();
    }
  },
  methods: {
    init() {
      this.tableData = [];
      this.requestData = true;
      this.configList = config;
      this.configLabel = configLabel;
      this.handleClickTitle();
    },

    // 切换tab
    handleClickTitle(tab) {
      this.activeName = tab?.name ? tab.name : 'import';
      console.log(this.activeName);
      this.handleTabClick();
    },

    handleTabClick(tab = 0) {
      this.activeTab = tab;
      // console.log(this.activeTab, tab);
      if (tab === 0) {
        const oneMonth = getLastMonth();
        this.tabMonth = [oneMonth.last, oneMonth.now];
      } else {
        const threeMonth = getLastThreeMonths();
        this.tabMonth = [threeMonth.last, threeMonth.now];
      }
      console.log(this.tabMonth);
      this.pageDataSize = 20;
      this.currentDataPage = 1;
      this.filterInit();
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage() || {};
      this.updateFilterList();
      this.GetTaskList();
    },

    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.GetTaskList();
    },

    updateFilterList() {
      const list = [];
      const { filterObject } = this;
      console.log(filterObject, '筛选filterObject');

      Object.keys(filterObject).forEach(key => {
        if (JSON.stringify(filterObject[key]) !== '{}') {
          list.push({ name: key, label: filterObject[key].label });
        }
      });
      this.filterList = list;
      console.log(this.filterList, '筛选条件list');
      this.setStorage();
    },

    // 展示筛选条件的标题
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.GetTaskList();
    },

    deleteAllFilter(flag = true) {
      localStorage.removeItem('importExport-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      if (flag) this.GetTaskList();
    },

    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
    },

    setStorage() {
      const filterData = this.getStorage();
      filterData['import-export'] = this.filterObject;
      localStorage.setItem('importExport-filter', JSON.stringify(this.filterObject));
    },
    getStorage() {
      const str = localStorage.getItem('importExport-filter');
      return str ? JSON.parse(str) : {};
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.GetTaskList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.GetTaskList();
    },

    viewExportSizeChange(val) {
      console.log(val);
      this.viewExportPageSize = val;
      this.requestImportData();
    },
    viewExportCurrentChange(val) {
      console.log(val);
      this.viewExportCurPage = val;
      this.requestImportData();
    },

    // 查看日志
    handleViewLog(index, row) {
      this.importLogDrawer = true;
      const resultLog = JSON.parse(row.result_preview);
      console.log(resultLog);
      this.importLogDetail = {
        logTime: resultLog['导入用时'],
        logNum: resultLog['导入数据总数'],
        logSuccess: resultLog['导入成功'],
        logFail: resultLog['导入失败'],
        logCreate: resultLog['新建数据'],
        logUpdate: resultLog['更新数据'],
        logDelete: resultLog['删除数据'],
      };
    },

    // 下载结果
    handleDownload(index, row) {
      console.log(index, row);
      GetObjURL({ url: row.file_url })
        .then(res => {
          if (row.file_name.endsWith('.csv')) {
            // 下载csv文件
            papaparse.parse(res.data.url, {
              download: true,
              complete(results, file) {
                console.log('Parsing complete:', results, file);
                if (results?.data) {
                  const csv = papaparse.unparse(results.data);
                  // console.log('downLoad', csv);
                  const content = new Blob([`\ufeff${csv}`], { type: 'text/plain;charset=utf-8' });
                  const urlObject = window.URL || window.webkitURL || window;
                  const url = urlObject.createObjectURL(content);
                  const el = document.createElement('a');
                  el.href = url;
                  el.download = row.file_name;
                  el.click();
                  urlObject.revokeObjectURL(url);
                }
              },
            });
          } else {
            // 下载excel文件
            getBlob(res.data.url, function(blob) {
              downloadFile(blob, row.file_name);
            });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 查看导入数据
    hanldeViewImportData(index, row) {
      console.log(index, row);
      this.viewImportDrawer = true;
      if (row?.data_type === '辖区' || this.$route.query.type === '辖区') {
        this.isOrg = false;
        this.getData();
      } else {
        this.isOrg = true;
        this.viewExportInfo.type = row?.data_type || this.$route.query.type;
        this.viewExportInfo.id = row?.task_id || this.$route.query.id;
        if (this.viewExportInfo.type === '机构') {
          const arr = [...hospitalConfig, ...dealerConfig, ...shopConfig];
          const arr1 = this.dealCommonData(arr);
          const arr2 = hcoCustom.map(ele => ele.key);
          arr1.sort((x, y) => {
            return arr2.indexOf(x.property) - arr2.indexOf(y.property);
          });

          this.viewExportConfig = arr1;
          this.viewExportLabel = hospitalLabelConfig;
        } else {
          this.viewExportConfig = doctorConfig;
          this.viewExportLabel = doctorLabelConfig;
        }
        this.configLabel = [...this.configLabel, ...this.viewExportLabel];
        this.requestImportData();
      }
    },

    // 辖区导入数据查看翻页功能
    handleJurisdictionSizeChange(val) {
      this.JurisdictionPage = val;
      this.getData();
    },
    handleJurisdictionCurrentChange(val) {
      this.JurisdictionIndex = val;
      this.getData();
    },
    // 请求辖区导入数据
    getData() {
      this.loading = true;
      const req = {
        index: this.JurisdictionIndex,
        page_size: this.JurisdictionPage,
      };
      GetJurisdictionData(req)
        .then(res => {
          this.JurisdictionTableData = res.datas.map(item => {
            return { ...JSON.parse(item) };
          });
          this.tableDataHead = res.headers;
          this.num = res.total;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          this.loading = false;
        });
    },

    // 请求导入数据列表
    requestImportData() {
      const params = {
        main_data: this.viewExportInfo.type === '机构' ? 't_hco' : 't_hcp',
        index: this.viewExportCurPage,
        page_size: this.viewExportPageSize,
        search_fields: this.dealDivisionParam(),
        data_array: [],
        SearchOptions: [
          {
            field_name: 'import_ids',
            data_name: this.viewExportInfo.type === '机构' ? 't_hco' : 't_hcp',
            type: 1, // 筛选类型； 1-包含，2-等于，3-大于，4-小于，5-大于等于，6-小于等于，7-不等于，8-不包含
            key: this.viewExportInfo.id,
          },
        ],
      };

      apis.SearchMainDataByType(params).then(res => {
        if (res?.data) {
          const dataList = res.data.map(item => JSON.parse(item));
          this.viewExportListNum = res.total_num;

          if (this.viewExportInfo.type === '机构') {
            // 处理行政区划、二级分类、类型细分
            this.dealFieldData(dataList);
          } else {
            // 处理标准科室
            this.dealStandardData(dataList);
          }

          this.dealDataLabel(dataList);
          this.viewExportList = dataList;
          console.log(this.viewExportList, '查看导入数据list');
        }
      });
    },

    // 处理请求字段
    dealDivisionParam() {
      let allProperty = this.viewExportConfig.map(item => item.property);

      // 医生，标准科室
      if (this.viewExportInfo.type === '医疗专业人士') {
        allProperty = [...allProperty, 'standard_dept_id', 'id', 'hcp_code', 'hco_id'];
      }

      // 机构，行政区划、二级分类、类型细分
      if (this.viewExportInfo.type === '机构') {
        const arr = [
          'province_name',
          'city_name',
          'district_name',
          'town_name',
          'village_name',
          'province_code',
          'city_code',
          'district_code',
          'town_code',
          'village_code',
        ];
        const hcoTypeArr = [
          'hco_type1',
          'hco_type_name1',
          'hco_type2',
          'hco_type_name2',
          'hco_type3',
          'hco_type_name3',
          'hco_type4',
          'hco_type_name4',
          'hco_type5',
          'hco_type_name5',
        ];
        allProperty = [...allProperty, ...hcoTypeArr, ...arr, 'id', 'hco_code'];
      }

      return allProperty;
    },

    // 防止出现相同数据
    dealCommonData(arr = []) {
      if (arr.length === 0) return;
      const obj = {};
      return arr.reduce((item, next) => {
        obj[next.property] ? '' : (obj[next.property] = true && item.push(next));
        return item;
      }, []);
    },

    // 获取任务列表
    GetTaskList() {
      const { ope_database, data_type, status } = this.filterObject;
      const opeDatabase = ope_database ? this.filterObject.ope_database.value : 0; // eslint-disable-line
      const dataType = data_type ? this.filterObject.data_type.value : 0; // eslint-disable-line
      const statusNum = status ? this.filterObject.status.value : 0;
      this.requestData = true;
      const params = {
        index: this.currentDataPage, // 页码
        page_size: this.pageDataSize, // 每页条数
        time_interval: this.tabMonth, // 时间区间
        task_type: this.activeName === 'import' ? 2 : 1, // 任务类型： 0-不做筛选、1-导出任务、2-导入任务
        ope_database: opeDatabase, // 操作的数据库：0-不做筛选、1-数据中心、2-数据中转区
        data_type: dataType, // 操作数据类型：0-不做筛选、1-机构、2-医疗专业人士
        create_user: this.role === 1 ? '' : this.userName, // 创建用户：空字符串-不做筛选
        create_time_order: this.orderTime.createTime ? Number(this.orderTime.createTime) : 2, // 创建时间排序方案 0-不排序，1-正序，2-倒序
        end_time_order: this.orderTime.endTime ? Number(this.orderTime.endTime) : 0, // 结束时间排序方案 0-不排序，1-正序，2-倒序，二者同时出现时，优先使用create_time进行排序
        status: statusNum, // 任务状态：0-不做筛选、1-排队中、2-执行中、3-已完成、4-任务失败
      };
      apis.GetDataImportExportTaskList(params).then(res => {
        if (res?.datas) {
          const dataList = res.datas;
          this.totalNum = res.total;

          // 处理表格数据
          this.dealDataLabel(dataList);
          this.tableData = dataList;
          console.log(this.tableData, 'tableData');
          setTimeout(() => {
            this.requestData = false;
          }, 500);
        }
      });
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.configLabel.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    hanldeSortChange(column) {
      console.log(column);
      if (column.order === 'descending') {
        this.orderTime =
          column.prop === 'create_time'
            ? { createTime: 2, endTime: '0' }
            : { endTime: 2, createTime: '0' };
      } else if (column.order === 'ascending') {
        this.orderTime =
          column.prop === 'create_time'
            ? { createTime: 1, endTime: '0' }
            : { endTime: 1, createTime: '0' };
      }
      this.GetTaskList();
    },

    handleImportLogClose() {
      this.importLogDrawer = false;
    },

    handleviewImportClose() {
      this.viewImportDrawer = false;
      this.viewExportList = [];
      this.viewExportConfig = [];
      this.viewExportCurPage = 1;
      this.viewExportPageSize = 20;
    },

    dealFieldData(dataList) {
      if (dataList.length === 0) return;
      dataList.forEach(ele => {
        const adminDivision = `${ele.province_name}/${ele.city_name}/${ele.district_name}/${ele.town_name}/${ele.village_name}`;
        ele.admin_division = this.delEndStr(adminDivision);

        const typeName = `${ele.hco_type_name3}/${ele.hco_type_name4}/${ele.hco_type_name5}`;
        ele.type_name = this.delEndStr(typeName);
        ele.hco_type = ele.hco_type_name2;
      });
    },

    dealStandardData(dataList) {
      if (dataList.length === 0) return;
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
      });
    },

    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },

    // 进入详情页
    gotoDetail(id, name, hcoType) {
      console.log(id, name, hcoType);
      const source = this.getSource(hcoType);
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { id, type: 'preview', source, name },
      });
      console.log(routerData);
      window.open(decodeURIComponent(routerData.href), '_blank');
    },

    // 所属机构名称跳转详情
    gotoHcoName(hcoName, hcoId) {
      const req = {
        obj_name: 't_hco',
        data_id: hcoId,
        fields: ['hco_type'],
      };
      apis
        .GetMainDataDetail(req)
        .then(res => {
          if (res?.data_detail) {
            const hcoType = JSON.parse(res.data_detail).hco_type;
            let source = '';
            Object.keys(REQUEST_TITLE_TYPE).forEach(ele => {
              if (REQUEST_TITLE_TYPE[ele] === hcoType) {
                source = ele;
              }
            });
            const routerData = this.$router.resolve({
              path: '/dashboard/data-detail',
              query: {
                id: hcoId,
                name: hcoName,
                type: 'preview',
                source,
              },
            });
            // console.log(decodeURIComponent(routerData.href));
            window.open(decodeURIComponent(routerData.href), '_blank');
          }
        })
        .catch(() => {
          this.$message.error('查不到数据，该数据可能已经被删除');
        });
    },

    getSource(hcoType) {
      if (!hcoType) return 'doctor';
      let source = '';
      const obj = {
        hospital: '医院',
        'hospital-ji': '基层医疗卫生机构',
        'hospital-pro': '专业公共卫生机构',
        'hospital-other': '其他医疗卫生机构',
        dealer: '经销商',
        shop: '药店',
        manufacturer: '生产企业',
        'medical-machine': '医疗器械',
        other: '其他分类',
      };
      Object.keys(obj).forEach(ele => {
        if (obj[ele] === hcoType) {
          source = ele;
        }
      });
      return source;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

export default [
  {
    title: '任务编码',
    width: 400,
    property: 'task_id',
    // showIcon: 'input',
  },
  {
    title: '任务类型',
    width: 200,
    property: 'task_type',
    // showIcon: 'select',
  },
  // {
  //   title: '操作数据库',
  //   width: 200,
  //   property: 'ope_database',
  //   showIcon: 'select',
  // },
  {
    title: '操作数据类型',
    width: 200,
    property: 'data_type',
    showIcon: 'select',
  },
  {
    title: '状态',
    width: 200,
    property: 'status',
    showIcon: 'select',
  },
  {
    title: '创建用户',
    width: 200,
    property: 'create_user',
    // showIcon: 'input',
  },
  {
    title: '创建时间',
    width: 300,
    property: 'create_time',
    sort: true,
    format: true,
  },
  {
    title: '完成时间',
    width: 300,
    property: 'end_time',
    sort: true,
    format: true,
  },
];

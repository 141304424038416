export default [
  {
    title: '任务类型',
    property: 'task_type',
    value: [
      { code: 1, label: '导出' },
      { code: 2, label: '导入' },
    ],
  },
  {
    title: '操作数据库',
    property: 'ope_database',
    value: [
      { code: 1, label: '数据中心' },
      { code: 2, label: '数据中转区' },
    ],
  },
  {
    title: '操作数据类型',
    property: 'data_type',
    value: [
      { code: 1, label: '机构' },
      { code: 2, label: '医疗专业人士' },
      { code: 3, label: '辖区' },
    ],
  },
  {
    title: '状态',
    property: 'status',
    value: [
      { code: 1, label: '排队中' },
      { code: 2, label: '执行中' },
      { code: 3, label: '已完成' },
      { code: 4, label: '任务失败' },
    ],
  },
];
